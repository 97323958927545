import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
	return (
		<Layout>
    	<div className="jumbotron">
    		<Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/impressum" />
        <h1>DSGVO / Impressum</h1>
        <h2>Datenschutzerklärung (nach DSGVO)</h2>
      </div>
	  	<div className="bgImage">
			  <p>
			    Diese Website ist ein Angebot im Sinne des <a href="https://www.gesetze-im-internet.de/tmg/TMG.pdf" rel="noopener">Telemediengesetzes (TMG)</a>.
			    <br/>
		 			Durch Aufruf und Nutzung dieser Seite übermitteln Sie auch Daten an uns. Der Umgang mit diesen Daten wird geregelt durch das TMG, die <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&amp;rid=1" rel="noopener">Datenschutz-Grundverordnung der EU (DSGVO &#8211; VO(EU)2016/679</a><a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&amp;rid=1" rel="noopener">)</a>, die <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32002L0058&amp;from=DE" rel="noopener">Richtlinie 2002/58/EG</a> und das <a href="https://www.bgbl.de/xaver/bgbl/start.xav?start=%2F%2F*%5B%40attr_id%3D%27bgbl117s2097.pdf%27%5D#__bgbl__%2F%2F*%5B%40attr_id%3D%27bgbl117s2097.pdf%27%5D__1527175363895" rel="noopener">Bundesdatenschutzgesetz (BDSG)</a>.
		 			<br />
		  		Eine dieser Vorschriften ist die Information der Nutzer über den Datenschutz bei der Erhebung und Verarbeitung personenbezogener Daten. Ihr entsprechen wir ausführlich auf dieser Seite, Stand 01. Januar 2020. Da wir unsere Website stets weiterentwickeln und ggf. den Umgang mit Ihren Daten neuen Regelungen anzupassen haben, empfiehlt es sich, von Zeit zu Zeit die Datenschutzerklärung nach Aktualisierungen durchzusehen. Sie erfahren nachstehend
				</p>
			  <ol>
			    <li>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen und Ihres Ansprechpartners</li>
			    <li>Umfang und Zweck der Datenerhebung</li>
			    <li>wie und wo wir Ihre Daten verarbeiten und speichern</li>
			    <li>Ihre Rechte (Betroffenenrechte)</li>
			    <li>mehr über unsere Links und Dienste Dritter</li>
			  </ol>
			  <h3>1) Name und Anschrift des für die Verarbeitung Verantwortlichen und Ihres Ansprechpartners</h3>
			  <p>Inhaber dieser Website ist <a href="https://kaiser-ulrich.de/">Prof. Dr. Ulrich Kaiser</a>.</p>
			  <p>
          Inhaber: Prof. Dr. Ulrich Kaiser<br/>
          Telefon: +49 89 289 27427<br/>
          Adresse dienstl.: Hochschule für Musik und Theater München, Arcisstr. 12, 80333 München<br/>
          Adresse privat: Birkenstr. 39c, 85757 Karlsfeld<br/>
          Mail: kontakt['at.]kaiser-ulrich.de<br/>
          Formulare: <a href="https://kaiser-ulrich.de/formular">privat</a> oder <a href="https://website.hmtm.de/de/hochschule/lehrende?inst=11">dienstlich</a>
			  </p>

			  <h3>2) Umfang und Zweck der Datenerhebung</h3>
			  <h4>Bereitstellung der Website und Erstellung von Logfiles</h4>
			  <p>
			    Diese Seite wird über ein Hosting-Paket bei 1&1 bereitgestellt. Bei jedem Aufruf unserer Internetseite könnten von 1&1 folgende Daten durch Serverlogfiles erhoben werden (die Angaben von 1&1 können Sie <a href="https://hosting.1und1.de/digitalguide/online-marketing/web-analyse/logfile-analyse-user-statistiken-dank-server-log/#c29331">hier</a> nachlesen):
			  </p>
			  <ol>
			    <li>IP-Adresse und Hostname</li>
			    <li>Zugriffszeitpunkt</li>
			    <li>vom Besucher verwendeter Browser</li>
			    <li>vom Besucher verwendetes Betriebssystem</li>
			    <li>Herkunftslink bzw. -URL</li>
			    <li>Verweildauer</li>
			    <li>Anzahl aufgerufener Seiten</li>
			    <li>zuletzt geöffnete Seite vor dem Verlassen der Website</li>
			  </ol>
			  <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
			  <p>
			    Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.<br />
			      Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben.<br />
			    Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.<br />
			    In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
			  </p>
			  <h4>Umfang der Verarbeitung personenbezogener Daten</h4>
			  <p>
			    Personenbezogene Daten von Nutzern werden grundsätzlich nur verarbeitet, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie der Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
			  </p>
			  <h4>Zweck der Datenverarbeitung</h4>
			  <p>
			    Die Verarbeitung der personenbezogenen Daten dient außerdem zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.<br />
			    Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, die Sicherheit unserer informationstechnischen Systeme sicherzustellen.
			  </p>
			  <h3>3) Wie und wo wir Ihre Daten verarbeiten und speichern</h3>
			  <p>
			    Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben.<br />
			    Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.<br />
			    In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
			  </p>
			  <h4>Dauer der Speicherung</h4>
			  <p>
			    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist.<br />
			    Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
			  </p>
			  <h4>E-Mail-Kontakt</h4>
			  <p>
			    Auf dieser Domain ist eine E-Mailadresse angegeben, welche für die elektronische Kontaktaufnahme genutzt werden kann. {/*Nimmt ein Nutzer die Möglichkeit der (externen) Formulare wahr, so werden die an uns übermittelten Daten zum Zweck der Kommunikation gespeichert.*/}
			  </p>

			  <h4>Dauer der Speicherung</h4>
			  <p>
			    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für Ihre personenbezogenen Daten ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Schreiben gesetzlichen Regelungen und eine Datenspeicherung vor, folgen wir selbstverständlich den gesetzlichen Vorgaben.
			  </p>
			  <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
			  <p>
			    Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.
			  </p>
			  <h3>4) Ihre Rechte (Rechte der betroffenen Person)</h3>
			  <p>
			    Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
			  </p>
			  <h4>Auskunftsrecht</h4>
			  <ol>
			    <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden</li>
			    <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden</li>
			    <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden</li>
			    <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer</li>
			    <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung</li>
			    <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
			    <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden</li>
			    <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
			  </ol>
			  <p>
			    Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
			  </p>
			  <h4>Recht auf Berichtigung</h4>
			  <p>
			    Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
			  </p>
			  <h4>Recht auf Einschränkung der Verarbeitung</h4>
			  <p>
			    Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
			  </p>
			  <ol>
			    <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen</li>
			    <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen</li>
			    <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
			    <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
			  </ol>
			  <p>
			    Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.<br />
			    Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
			  </p>
			  <h4>Recht auf Löschung (Löschungspflicht)</h4>
			  <p>
			    Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
			  </p>
			  <ol>
			    <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
			    <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
			    <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
			    <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
			    <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
			    <li>  Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
			  </ol>
			  <h4>Informationen an Dritte</h4>
			  <p>
			    Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
			  </p>
			  <h4>Ausnahmen</h4>
			  <p>
			    Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
			  </p>
			  <ol>
			    <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information</li>
			    <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde</li>
			    <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO</li>
			    <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
			    <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
			  </ol>
			  <h4>Recht auf Unterrichtung</h4>
			  <p>
			    Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.<br />
			    Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
			  </p>
			  <h4>Recht auf Datenübertragbarkeit</h4>
			  <p>
			    Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
			  </p>
			  <ol>
			    <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und</li>
			    <li>
			      die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
			    </li>
			  </ol>
			  <p>
			    In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.<br />
			    Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
			  </p>
			  <h4>Widerspruchsrecht</h4>
			  <p>
			    Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.<br />
			    Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br />
			    Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.<br />
			    Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.<br />
			    Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
			  </p>
			  <h4>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
			  <p>
			    Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
			  </p>
			  <h4>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h4>
			  <p>
			    Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
			  </p>
			  <ol>
			    <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
			    <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
			    <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
			  </ol>
			  <p>
			    Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.<br />
			    Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
			   </p>
			   <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
			   <p>
			    Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.<br />
			    Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
			  </p>

			  <h2>Impressum</h2>
			  <p><b>Angaben gemäß § 5 TMG und verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</b></p>
			  <address>
			    <span>Ulrich Kaiser</span><br />
			    <span>Birkenstr. 39c</span><br />
			    <span>85757 Karlsfeld</span>
			  </address>
			  <h4>Kontakt:</h4>
			  <address>
			    <span>Telefon: +89 / 289-27427</span><br />
			    <span>Mail: kontakt['at.]kaiser-ulrich.de</span>
			  </address>
			  <h4>Online-Streitbeilegung</h4>
			  <p>
			    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a><br/>
			    Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
			  </p>
			  <h4>Haftung für Inhalte</h4>
			  <p>
			    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
			  </p>
			  <p>
			    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
			  </p>
			  <h4>Haftung für Links</h4>
			  <p>
			    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
			  </p>
			  <h4>Urheberrecht</h4>
			  <p>
			    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
			  </p>
			  <p>
			    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
			  </p>
			  <h4>Widerspruch Werbe-Mails</h4>
			  <p>
			    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
			  </p>
			  <p>Quelle: <a href="https://www.e-recht24.de/">eRecht24</a></p>
			</div>
			<Helmet>
        <title>OER | Ulrich Kaiser - Impressu/DSGVO</title>
        <meta name="description" content="Hier finden Sie die rechtlichen Informationen zu dieser Website." />
        <meta name="keywords" content="Impressum, DSGVO, TMG" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
	  </Layout>
	)
}
